
    .myCat{
         color: rgb(0, 0, 128);
    }
    .myCat:hover {
       text-decoration: underline;
       text-decoration-color: rgb(0, 0, 128);
    }      
 

    .myName{
        color: rgb(0, 0, 0);
     }

     .myName:hover {
      text-decoration: underline;
     text-decoration-color: rgb(0, 0, 0);
    }  
    
    .menuItems{
        color: rgb(0, 0, 0);
    }

    .menuItems::after{
        content: "";
        display: block;
         height: 2px;
        margin-left: auto;
        margin-right: auto;
         width: 0px;
        background-color: rgb(0, 0, 128);
    }

    .menuItems:hover::after{
        width: 100%;
        transition: all 0.4s;
    }

    span {
        color: #ff9933;
      }
